import React, { useEffect, useRef, useState } from "react";
import { gsap } from "gsap";
import "../styles/header.css";
import AboutPangisa from "./AboutPangisa";
import sliderData from "./sliderData.js";
import { AiOutlineArrowLeft, AiOutlineArrowRight } from "react-icons/ai";

const Header = (props) => {
  //services animation
  let coverImg = useRef(null);
  let headerInfo = useRef(null);
  let headerCircle = useRef(null);
  let headerCircle2 = useRef(null);
  let headerText = useRef(null);
  let headerBtn = useRef(null);

  //setting states
  const [showAbout, setShowAbout] = useState(false);

  //handle sliding images
  const [currentSlide, setCurrentSlide] = useState(0);
  const slideLength = sliderData.length;

  const autoScroll = true;
  let slideInterval;
  let intervalTime = 9000;

  const nextSlide = () => {
    setCurrentSlide(currentSlide === slideLength - 1 ? 0 : currentSlide + 1);
  };

  const prevSlide = () => {
    setCurrentSlide(currentSlide === 0 ? slideLength - 1 : currentSlide - 1);
    console.log("prev");
  };

  function auto() {
    slideInterval = setInterval(nextSlide, intervalTime);
  }

  useEffect(() => {
    setCurrentSlide(0);
  }, []);

  useEffect(() => {
    if (autoScroll) {
      auto();
    }
    return () => clearInterval(slideInterval);
  }, [currentSlide]);

  //show about pangisa
  const handleShowAboutPangisa = () => {
    setShowAbout((prev) => !prev);
  };

  //take effect after DOM elements load
  useEffect(() => {
    const tlHeader = gsap.timeline({
      defaults: { duration: 0.75, ease: "power1.out" },
    });
    tlHeader
      .fromTo(
        coverImg.current,
        { scale: 1.3 },
        { scale: 1, delay: 0.35, duration: 2.5, ease: "elastic.out(1.5,1)" }
      )

      .from(".header-text", 1, { opacity: 0, y: -50 }, "<50%")
      .from(".header-btn", { opacity: 0, y: 100 }, "<");

    tlHeader.fromTo(
      headerCircle.current,
      10,
      { y: "-50%", opacity: 0.5, rotation: "0deg" },
      { y: "70%", opacity: 0.9, rotation: "-50deg", repeat: -1, yoyo: true }
    );
    tlHeader.fromTo(
      headerCircle2.current,
      10,
      { y: "-50%", opacity: 0.5, rotation: "0deg" },
      {
        y: "100%",
        x: "50%",
        opacity: 0.9,
        rotation: "-50deg",
        repeat: -1,
        yoyo: true,
      }
    );
  }, []);
  return (
    <section id="home" className="header_container">
      <div className="header-gradient"></div>
      <div ref={headerInfo} className="header-info">
        <div ref={headerText} className="header-text">
          <h1 className="header-title">Welcome to Pangisa</h1>
          <h4 className="header-subtitle">One Stop Centre</h4>
          <div className="header-line"></div>

          <p className="header-content">
            The central place for all your E-commerce and Business needs.
            Connecting you to all your digital oriented solutions...
            <button onClick={handleShowAboutPangisa} className="read-more">
              Read More...
            </button>
          </p>
        </div>
        <a
          href="https://pangisa.co.ug/shop/Home/"
          ref={headerBtn}
          id="#services"
          className="header-btn"
        >
          <span>Shop Now</span>
          <i className="fa-solid fa-arrow-right-long"></i>
        </a>
      </div>
      {
        <div className="slider-header ">
          <AiOutlineArrowLeft className="arrow prev" onClick={prevSlide} />
          <AiOutlineArrowRight className="arrow next" onClick={nextSlide} />
          {sliderData.map((slide, index) => {
            return (
              <div
                className={
                  index === currentSlide
                    ? "slide-header  current"
                    : "slide-header"
                }
                key={index}
              >
                {index === currentSlide && (
                  <>
                    <img
                      ref={coverImg}
                      src={slide.image}
                      alt="slide"
                      className="image"
                    />
                  </>
                )}
              </div>
            );
          })}
        </div>
      }
      <svg
        ref={headerCircle}
        className="circle circle-1 header-circle"
        width="200"
        height="200"
        viewBox="0 0 243 243"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle
          cx="121.5"
          cy="121.5"
          r="121.5"
          fill="white"
          fillOpacity="0.25"
        />
      </svg>
      <svg
        ref={headerCircle2}
        className="circle circle-1 header-circle"
        width="243"
        height="243"
        viewBox="0 0 243 243"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle
          cx="121.5"
          cy="121.5"
          r="121.5"
          fill="white"
          fillOpacity="0.25"
        />
      </svg>
      {/* render about pangisa */}
      {showAbout && (
        <AboutPangisa
          showAboutPangisaState={showAbout}
          hideAbout={handleShowAboutPangisa}
          showContactForm={props.setShowContactForm}
        />
      )}
    </section>
  );
};

export default Header;
