import React, { useEffect, useRef, useState } from "react";
import { TweenMax } from "gsap";

import Nav from "./components/Nav";
import Header from "./components/Header";
import Services from "./components/Services";
import Slider from "./components/Slider";
import Content from "./components/Content";
import Footer from "./components/Footer";
import Resources from "./components/Resources/Resources.js";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

export default function App() {
  //GLOBAL STATES
  //set states for contact us form
  const [showContactForm, setShowContactForm] = useState(false);

  let contactFormRevealAnim = useRef(null);
  let contactFormAnim = useRef(null);
  //animate in the contact form
  useEffect(() => {
    const tlLogIssue = gsap.timeline({
      defaults: { duration: 0.75, ease: "power1.out" },
    });
    if (showContactForm) {
      tlLogIssue
        .from(contactFormRevealAnim.current, { opacity: 1 })
        .from(contactFormAnim.current, { x: "100%" }, "<");
    } else {
      tlLogIssue
        .to(contactFormRevealAnim.current, 1, { opacity: 0 })
        .to(contactFormAnim.current, 1, { x: "100%" }, "<");
    }
  }, [showContactForm]);
  //company address
  const aboutCompany = {
    companyName: "Pangisa",
    location: "Plot 92, Kanjokya Street, Kanjokya House, Kampala",
    boxAddress: "P.O BOX 106714, Kampala, Uganda ",
    phone: "0393236376",
    landLine: "0200770500, 0740594860",
    email: "info@pangisa.co.ug",
  };

  //destructing company address
  const { companyName, location, boxAddress, phone, landLine, email } =
    aboutCompany;

  //return current year
  let currentYear = new Date().getFullYear();

  //aninate app container
  let appAnim = useRef();
  useEffect(() => {
    TweenMax.to(appAnim.current, 0, { css: { visibility: "visible" } });
  }, []);

  return (
    <div ref={appAnim} className="app-container">
      {/* APP COMPONENTS */}
      <Nav
        showContactForm={showContactForm}
        setShowContactForm={setShowContactForm}
        contactFormRevealAnim={contactFormRevealAnim}
        contactFormAnim={contactFormAnim}
      />
      <Header
        showContactForm={showContactForm}
        setShowContactForm={setShowContactForm}
        contactFormRevealAnim={contactFormRevealAnim}
        contactFormAnim={contactFormAnim}
      />
      <Services />
      <Content />
      <Slider />
      <Resources />
      <Footer
        companyName={companyName}
        currentYear={currentYear}
        boxAddress={boxAddress}
        physicalAddress={location}
        phone={phone}
        landLine={landLine}
        email={email}
      />
    </div>
  );
}
