import React from "react";
import logo from "../images/icon1.png";
import "../styles/footer.css";

export default function Footer({
  companyName,
  currentYear,
  boxAddress,
  physicalAddress,
  phone,
  landLine,
  email,
}) {
  return (
    <section className="footer-container">
      <footer>
        <div className="logo-footer">
          <img src={logo} alt="logo-img" />

          <div className="socials-container">
            <p className="socials-title">Follow Us</p>
            <div className="socials">
              <i className="fa-brands fa-facebook-square"></i>
              <i className="fa-brands fa-twitter-square"></i>
              <i className="fa-brands fa-instagram-square"></i>
            </div>
          </div>
          <p className="copyright">
            &copy; <b>{currentYear}</b> {companyName} is Powered by Nepserv
            Consults Limited <b></b>{" "}
          </p>
        </div>

        <div className="footer-services">
          <h2>Our Services</h2>
          <a href="#" className="shop">
            Request Consultation
          </a>
          <a href="#" className="shop">
            Rent Equipmemnt
          </a>
          <a href="#" className="shop">
            Shop an Item
          </a>
          <a href="#" className="shop">
            Manage ERP
          </a>
        </div>

        <div className="address">
          <h2>For further engagements, Reach us on; </h2>

          <div className="physical-address-container">
            <i className="fa-solid fa-location-dot"></i>
            <p className="physical-address">{physicalAddress}</p>
          </div>
          <div className="physical-address-container">
            <i className="fa-solid fa-location-dot"></i>
            <p className="physical-address">{boxAddress}</p>
          </div>

          <div className="email-container">
            <i className="fa-solid fa-envelope"></i>
            <a href="mailto:info@pangisa.co.ug" className="email">
              {email}
            </a>
          </div>

          <div className="phone-container">
            <i className="fa-brands fa-whatsapp"></i>
            <p className="contact-nr">
              {phone}, <i class="fa-solid fa-phone-flip"></i> {landLine}
            </p>
          </div>
        </div>
      </footer>
    </section>
  );
}
