import React, { useState, useEffect, useRef } from "react";
import FreqQuestions from "./FreqQuestions";
import HowPangisaWorks from "./HowPangisaWorks";
import TermsAndConditions from "./TermsAndConditions";
import { gsap } from "gsap";

import "../../styles/resources.css";

const Resources = () => {
  //set content rendering states
  const [isFrequentlyAsked, setIsFrequentlyAsked] = useState(true);
  const [isHowItWorks, setHowItWorks] = useState(false);
  const [isTermsAndConditions, setTermsAndConditions] = useState(false);

  //handle resource rendering
  function showHowItWorksContent() {
    setIsFrequentlyAsked(false);
    setHowItWorks(true);
    setTermsAndConditions(false);
  }
  function showFrequentlyAsked() {
    setIsFrequentlyAsked(true);
    setHowItWorks(false);
    setTermsAndConditions(false);
  }
  function showTandCs() {
    setIsFrequentlyAsked(false);
    setHowItWorks(false);
    setTermsAndConditions(true);
  }
  //animating in borders for how_works
  useEffect(() => {
    // apply border styles
    const tlBorder = gsap.timeline({
      defaults: { duration: 0.75, ease: "power3.out" },
    });
    if (isHowItWorks) {
      tlBorder.set(".works-btn", {
        border: "1px solid rgb(250, 182, 48)",
        borderBottom: "none",
        opacity: 1,
      });
    } else {
      tlBorder.set(".works-btn", {
        border: "none",
        opacity: 0.3,
      });
    }
  }, [isHowItWorks]);
  //animating in borders for tandcs
  useEffect(() => {
    // apply border styles
    const tlBorder = gsap.timeline({
      defaults: { duration: 0.75, ease: "power3.out" },
    });
    if (isTermsAndConditions) {
      tlBorder.set(".terms-btn", {
        border: "1px solid rgb(250, 182, 48)",
        borderBottom: "none",
        opacity: 1,
      });
    } else {
      tlBorder.set(".terms-btn", {
        border: "none",
        opacity: 0.3,
      });
    }
  }, [isTermsAndConditions]);

  //animating in borders for fandqs
  const fAndQImgRef = useRef(null);
  useEffect(() => {
    // apply border styles
    const tlBorder = gsap.timeline({
      defaults: { duration: 0.75, ease: "power3.out" },
    });
    if (isFrequentlyAsked) {
      tlBorder.set(".f-and-q-btn", {
        border: "1px solid rgb(250, 182, 48)",
        borderBottom: "none",
        opacity: 1,
      });
      tlBorder.from(fAndQImgRef.current, { x: "-10%", opacity: 0 });
    } else {
      tlBorder.set(".f-and-q-btn", {
        border: "none",
        opacity: 0.3,
      });
    }
  }, [isFrequentlyAsked]);
  return (
    <div className="resources-section">
      <header className="resources-head">
        <div className="resources-wrapper">
          <div className="resources-line-left"></div>
          <h2>Pangisa | Resources </h2>
          <div className="resources-line-right"></div>
        </div>
      </header>

      <div className="resources-titles">
        <button onClick={showFrequentlyAsked} className="f-and-q-btn">
          Frequently Asked Questions(F&Q)
        </button>
        <button onClick={showHowItWorksContent} className="works-btn">
          How Pangisa Works
        </button>
        <button onClick={showTandCs} className="terms-btn">
          Terms & Conditions(T&C)
        </button>
      </div>

      <div className="content-container">
        {isFrequentlyAsked && <FreqQuestions isShown={isFrequentlyAsked} />}
        {isHowItWorks && <HowPangisaWorks isShown={isHowItWorks} />}
        {isTermsAndConditions && (
          <TermsAndConditions isShown={isTermsAndConditions} />
        )}
      </div>
    </div>
  );
};

export default Resources;
