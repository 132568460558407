import React, { useState, useEffect, useRef } from "react";
import { gsap } from "gsap";
import freqAndQnData from "./fAndQData";

const FreqQuestions = (props) => {
  //animate f-and-q section
  const fAndQImgRef = useRef(null);
  let questionContainerRef = useRef(null);
  let questionRef = gsap.utils.selector(questionContainerRef);

  useEffect(() => {
    // apply border styles
    const tlBorder = gsap.timeline({
      defaults: { duration: 0.75, ease: "power3.out" },
    });
    if (props.isShown) {
      tlBorder.from(fAndQImgRef.current, { x: "-100%", opacity: 0 });
      tlBorder.from(".question-answer", {
        opacity: 0,
        x: "-10%",
        stagger: 0.5,
      });
    }
  }, [props.isShown]);

  // pulling freq and Answer data
  const fQs = freqAndQnData.map((freqQuestion) => {
    return (
      <QuestionAnswer
        setStagger={props.isShown}
        question={freqQuestion.question}
        answer={freqQuestion.answer}
      />
    );
  });
  return (
    <>
      <div className="left-content">
        <video
          ref={fAndQImgRef}
          src="../images/rolling.mp4"
          autoPlay
          muted
          loop
        ></video>
      </div>
      <div className="right-content">{fQs}</div>
    </>
  );
};

//
const QuestionAnswer = (props) => {
  return (
    <div className="question-answer">
      <div className="question-container">
        <h3 className="question-lable">Q:</h3>
        <p className="question">{props.question}</p>
      </div>

      <div className="answer-container">
        <h3 className="answer-lable">A:</h3>
        <p className="answer">{props.answer}</p>
      </div>
    </div>
  );
};
export default FreqQuestions;
