import React, { useState, useEffect, useRef } from "react";

import SiteService from "./SiteService";
import servicesData from "./servicesData";
import "../styles/services.css";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

export default function Services() {
  const [services, setServices] = useState(servicesData);

  function handleMouseOver(id) {
    setServices((prevService) => {
      return prevService.map((service) => {
        //check if id in current service obj is equal to passed id
        return service.id === id
          ? { ...service, isHover: !service.isHover }
          : service;
      });
    });
  }
  function handleMouseOut(id) {
    setServices((prevService) => {
      return prevService.map((service) => {
        //check if id in current service obj is equal to passed id
        return service.id === id
          ? { ...service, isHover: !service.isHover }
          : service;
      });
    });
  }
const servicesRef = useRef(null)
  //handle scroll
  function handleScroll(event){
    event.preventDefault();
    event.stopPropagation();
    servicesRef.current.scrollLeft += event.deltaY;
  }
  //loop through services data and return Service instance
  const exampleSerives = services.map((services) => {
    return (
      <SiteService
        key={services.id}
        id={services.id}
        service_link={services.serviceLink}
        service_title={services.serviceTitle}
        service_icon={services.icon}
        description={services.description}
        handleMouseOver={handleMouseOver}
        handleMouseOut={handleMouseOut}
        hover={services.isHover}
      />
    );
  });

  return (
    <div id="services" className="services-container">
      <img
        className="service-cover-img"
        src="./shopped-items.png"
        alt="cover-img"
      />
      <div className="service-gradient"></div>
      <h3 className="services-head">
        Its That Easy...<span>&#128522;</span>
      </h3>
      <div ref={servicesRef}  onWheelCapture={handleScroll} className="services">{exampleSerives}</div>
    </div>
  );
}
