import React from "react";
import SliderImage from "./SliderImage";

import shopImage from "../images/bruno-kelzer.jpg";
import rentImage from "../images/charlesdeluvi.jpg";
import consultImage from "../images/erik-mclean.jpg";
import bookImage from "../images/fons-heijnsbroek.jpg";
import travelImage from "../images/testalize-me.jpg";
import investImage from "../images/jacek-dylag.jpg";
import "../styles/slider.css";

export default function Slider() {
  //images that represent links to other sites
  const siteImages = {
    shopItemSiteImg: shopImage,
    rentItemSiteImg: rentImage,
    consultSiteImg: consultImage,
    bookSiteImg: bookImage,
    travelSiteImg: travelImage,
    investSiteImg: investImage,
  };
  const {
    shopItemSiteImg,
    rentItemSiteImg,
    consultSiteImg,
    bookSiteImg,
    travelSiteImg,
    investSiteImg,
  } = siteImages;

  //href links to other sites
  const site_links = {
    shopItemSiteLink: "anchor_href",
    rentItemSiteLink: "anchor_href",
    consultItemSiteLink: "anchor_href",
    bookItemSiteLink: "anchor_href",
    travelSiteLink: "anchor_href",
    investSiteLink: "anchor_href",
  };
  const {
    shopItemSiteLink,
    rentItemSiteLink,
    consultItemSiteLink,
    bookItemSiteLink,
    travelSiteLink,
    investSiteLink,
  } = site_links;

  //images that represent links to other sites
  const siteTitles = {
    shopSiteTitle: "Shop",
    rentSiteTitle: "Rent",
    consultSiteTitle: "Consult",
    bookSiteTitle: "Book",
    travelSiteTitle: "Travel",
    InvestSiteTitle: "Invest",
  };
  const {
    shopSiteTitle,
    rentSiteTitle,
    consultSiteTitle,
    bookSiteTitle,
    travelSiteTitle,
    InvestSiteTitle,
  } = siteTitles;

  return (
    <div id="trade-assured" className="slider-content">
      <div className="slider-head">
        <p className="slider-title">
          Buy or rent or lease or seek expertise from our Trade Assured and
          verified vendors below. Get started by clicking on any of your
          preferences below{" "}
        </p>
      </div>

      <div className="slider-container">
        {/* Slider images instances */}
        <SliderImage
          site_img={shopItemSiteImg}
          site_link={shopItemSiteLink}
          site_title={shopSiteTitle}
        />
        <SliderImage
          site_img={rentItemSiteImg}
          site_link={rentItemSiteLink}
          site_title={rentSiteTitle}
        />
        <SliderImage
          site_img={consultSiteImg}
          site_link={consultItemSiteLink}
          site_title={consultSiteTitle}
        />
        <SliderImage
          site_img={bookSiteImg}
          site_link={bookItemSiteLink}
          site_title={bookSiteTitle}
        />
        <SliderImage
          site_img={travelSiteImg}
          site_link={travelSiteLink}
          site_title={travelSiteTitle}
        />
        <SliderImage
          site_img={investSiteImg}
          site_link={investSiteLink}
          site_title={InvestSiteTitle}
        />
        <SliderImage
          site_img={bookSiteImg}
          site_link={bookItemSiteLink}
          site_title={bookSiteTitle}
        />
        <SliderImage
          site_img={rentItemSiteImg}
          site_link={rentItemSiteLink}
          site_title={rentSiteTitle}
        />
      </div>
    </div>
  );
}
