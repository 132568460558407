import React, { useState, useEffect, useRef } from "react";
import { gsap } from "gsap";
import { client, vendor } from "./howItWorksData";

const HowPangisaWorks = (props) => {
  //set content rendering states
  const [isVendor, setVendor] = useState(true);
  const [isClient, setClient] = useState(false);

  //handle content rendering
  function showVendor() {
    setClient(false);
    setVendor(true);
  }
  function showClient() {
    setVendor(false);
    setClient(true);
  }
  //animate how_workds section
  const howWorksImgRef = useRef(null);
  useEffect(() => {
    const tlBorder = gsap.timeline({
      defaults: { duration: 1, ease: "power3.out" },
    });
    if (props.isShown) {
      tlBorder.from(howWorksImgRef.current, { x: "-100%", opacity: 0 });
      tlBorder.from(".vender-item", {
        opacity: 0,
        x: "100%",
        stagger: 0.5,
      });
    }
  }, [props.isShown]);

  // rending the vendor and client data
  const clientData = client.map((clientInfo) => {
    return <Client info={clientInfo} />;
  });
  const vendorData = vendor.map((vendorInfo) => {
    return <Vendor info={vendorInfo} />;
  });
  // rending html
  return (
    <>
      <div className="left-content_how_works  ">
        <div className="left-content-btns">
          <button onClick={showVendor} className="vender-content-btn">
            VENDOR
          </button>
          <button onClick={showClient} className="client-content-btn">
            CLIENT
          </button>
        </div>
        <div className="howItWorks-content">
          {isVendor && <ol className="vendor-list">{vendorData}</ol>}
          {isClient && <ol className="client-list">{clientData}</ol>}
        </div>
      </div>
      <div className="right-content_how_works">
        <video
          ref={howWorksImgRef}
          src="../images/home-shoe.mp4"
          autoPlay
          muted
          loop
        ></video>
      </div>
    </>
  );
};

//CLIENT
const Client = (props) => {
  return <li className="client-item">{props.info}</li>;
};

//VENDOR
const Vendor = (props) => {
  return <li className="vender-item">{props.info}</li>;
};
export default HowPangisaWorks;
