import React, { useEffect, useRef, useState } from "react";
import Cart from "./Cart";
import NavLinksMobile from "./NavLinksMobile";
import LogIssue from "./LogIssue";
import { TweenMax, Power3, gsap } from "gsap";
import "../styles/nav.css";

export default function Nav(props) {
  // animaiton refs
  let logoItem = useRef(null);
  let cart = useRef(null);
  let navLinks = useRef();
  const navItems = gsap.utils.selector(navLinks);
  let headerBtns = useRef();
  const buttons = gsap.utils.selector(headerBtns);

  const [showCartInfo, setShowCartInfo] = useState(false);

  const [animateNavBarLinks, setAnimateNavBarLinks] = useState(false);
  const [animateCartSec, setAnimateCartSec] = useState(false);
  //set mobile navbar to display
  const [isMobileNavDisplayed, setIsMobileNavDisplayed] = useState(false);

  //animation refs

  function cancelContactForm() {
    props.setShowContactForm((prev) => !prev);
  }
  useEffect(() => {
    TweenMax.fromTo(
      logoItem,
      1,
      { y: 50 },
      {
        opacity: 1,
        y: 0,
        ease: Power3.easeOut,
      }
    );
    TweenMax.from(cart.current, 2, {
      opacity: 0,
      x: 50,
      ease: Power3.easeOut,
      delay: 1,
    });
  }, []);
  //show cart info
  function handleCart() {
    if (props.showContactForm) {
      props.setShowContactForm((prev) => !prev);
      setShowCartInfo((prev) => !prev);
    } else {
      setShowCartInfo((prev) => !prev);
    }
  }
  //show help
  function handleHelp() {
    if (showCartInfo) {
      setShowCartInfo((prev) => !prev);
      props.setShowContactForm((prev) => !prev);
    } else {
      props.setShowContactForm((prev) => !prev);
    }
  }
  //Animate nav links
  function handleMouseEnter() {
    setAnimateNavBarLinks((prev) => !prev);
  }
  function handleMouseLeave() {
    setAnimateNavBarLinks((prev) => !prev);
  }
  useEffect(() => {
    if (animateNavBarLinks) {
      gsap.to(navItems(".nav-item"), {
        y: 10,
        stagger: 0.33,
      });
    } else {
      gsap.to(navItems(".nav-item"), {
        y: 0,
        stagger: 0.33,
      });
    }
  }, [animateNavBarLinks, navItems]);

  //Animate cart section
  function handleMouseEnterCartSec() {
    setAnimateCartSec((prev) => !prev);
  }
  function handleMouseLeaveCartSec() {
    setAnimateCartSec((prev) => !prev);
  }
  useEffect(() => {
    if (animateCartSec) {
      gsap.to(buttons("button"), {
        y: 10,
        stagger: 0.1,
      });
    } else {
      gsap.to(buttons("button"), {
        y: 0,
        stagger: 0.1,
      });
    }
  }, [animateCartSec, buttons]);

  //animation cart refs
  let cartInfoAnim = useRef(null);
  let cartInfoRevealAnim = useRef(null);

  //cancel cart info
  function handleCancelCart() {
    if (showCartInfo) {
      setShowCartInfo((prev) => !prev);
    }
  }
  useEffect(() => {
    if (showCartInfo) {
      gsap.from(cartInfoRevealAnim.current, 0.75, { opacity: 1 });
      gsap.from(cartInfoAnim.current, 0.75, { x: "100%" });
    } else {
      gsap.to(cartInfoRevealAnim.current, 1, { opacity: 0 });
      gsap.to(cartInfoAnim.current, 1, { x: "100%" });
    }
  }, [showCartInfo]);

  //display navlinks on burger click
  function showNavLinks() {
    setIsMobileNavDisplayed((prev) => !prev);
  }
  useEffect(() => {
    if (isMobileNavDisplayed) {
    }
  }, [isMobileNavDisplayed]);

  return (
    <div className="nav-container">
      <nav>
        <img
          ref={(el) => {
            logoItem = el;
          }}
          src="../images/3.png"
          alt="logo-img"
        />

        <div className="nav-links">
          <ul
            ref={navLinks}
            onMouseEnter={handleMouseEnter}
            onMouseOut={handleMouseLeave}
          >
            <li className="nav-item">
              <a href="#home">Home</a>
            </li>
            <li className="nav-item">
              <a href="#services">Services</a>
            </li>
            <li className="nav-item">
              <a href="#benefits">Benefits</a>
            </li>
            <li className="nav-item">
              <a href="#trade-assured">Trade Assured</a>
            </li>
          </ul>
          <div
            ref={cart}
            onMouseEnter={handleMouseEnterCartSec}
            onMouseLeave={handleMouseLeaveCartSec}
            className="cart-container"
          >
            <div className="nav-btns" ref={headerBtns}>
              <button className="help-btn" onClick={handleHelp}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="15"
                  height="15"
                  viewBox="0 0 32 32"
                >
                  <path
                    fill="#FFF"
                    fill-rule="evenodd"
                    d="M0 9h13.6c1.326 0 2.4-.895 2.4-2H0V4c0-2.21 1.79-4 4-4h24c2.21 0 4 1.79 4 4v17c0 2.21-1.79 4-4 4h-5v4.282c0 2.195-2.55 3.482-4.404 2.223L9 25H4c-2.21 0-4-1.79-4-4v-3h9.75c1.243 0 2.25-.895 2.25-2H0V9z"
                  ></path>
                </svg>{" "}
                <span className="contact-icon_text">CONTACT US</span>
              </button>
            </div>
          </div>
        </div>
        <NavLinksMobile />
        {/* burger icon */}
        <svg
          onClick={showNavLinks}
          className="burger"
          width="50"
          height="23"
          viewBox="0 0 50 23"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <line y1="1.5" x2="50" y2="1.5" stroke="white" strokWidth="3" />
          <line
            y1="11.5"
            x2="50"
            x1="20"
            y2="11.5"
            stroke="white"
            strokeWidth="3"
          />
          <line
            y1="21.5"
            x2="50"
            x1="35"
            y2="21.5"
            stroke="white"
            strokWidth="3"
          />
        </svg>
      </nav>
      {showCartInfo ? (
        <Cart
          cartInfoShown={showCartInfo}
          handleCancelCart={handleCancelCart}
          cartInfoAnim={cartInfoAnim}
          cartInfoRevealAnim={cartInfoRevealAnim}
        />
      ) : (
        ""
      )}
      {props.showContactForm ? (
        <LogIssue
          cancelContactForm={cancelContactForm}
          contactFormShown={props.showContactForm}
          contactFormAnim={props.contactFormAnim}
          contactFormRevealAnim={props.contactFormRevealAnim}
        />
      ) : (
        ""
      )}
    </div>
  );
}
