import React, { useRef, useEffect } from "react";
import { gsap, Power3 } from "gsap";
import "../styles/services.css";

export default function SiteService(props) {
  //services animation
  let serviceAnim = useRef(null);
  let serviceIconAnim = useRef();
  let descriptionAnim = useRef();
  let titleAnim = useRef();

  //take effect after DOM elements load
  useEffect(() => {
    gsap.from(serviceAnim.current, 2, {
      x: -100,
      opacity: 0,
      scale: 0.5,
      stagger: {
        each: 0.5,
        from: "center",
        grid: "auto",
        ease: "Power3.inOut",
      },
      delay: 0.5,
    });
  }, []);

  //animate the mounted service description
  useEffect(() => {
    const tlServices = gsap.timeline({
      defaults: { duration: 0.75, ease: "power3.out" },})

    if (props.hover) {
      tlServices.fromTo(
        serviceAnim.current,
        {
          y: "0%",
        },
        {
          y: "-5%", rotate:5,
        }
      );
      tlServices.to(descriptionAnim.current, 0.75, {
        y: 0,
      }, "<");
      tlServices.to(serviceIconAnim.current, 0.75, {
        y: -5,
      });
      tlServices.to(
        titleAnim.current,
        0.75,
        {
          y: 0,
          delay: 1,
          ease: Power3.easeIn,
        },
        "<"
      );
    } else {
      tlServices.fromTo(
        serviceAnim.current,
        {
          y: "-5%", rotate:5
        },
        {
          y: "0%",rotate:0
        }
      );
    }
  }, [props.hover]);

  return (
    <a
      //function that runs props.handleMouseOver pass the id for a box
      onMouseOver={() => props.handleMouseOver(props.id)}
      onMouseOut={() => props.handleMouseOut(props.id)}
      ref={serviceAnim}
      href={props.service_link}
      className="service service-1"
    >
      <div className="card-title-container">
        <p ref={serviceIconAnim} className="service-icon">
          <i className={props.service_icon}></i>{" "}
        </p>
        <span ref={titleAnim}>{props.service_title}</span>
      </div>
      {props.hover && (
        <p ref={descriptionAnim} className="service-description">
          {props.description}
        </p>
      )}
    </a>
  );
}
