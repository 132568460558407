import React, { useEffect, useRef } from "react";
import { gsap, Power3 } from "gsap";

export default function SliderImage({ site_img, site_link, site_title }) {
  //animate sliding services images - which will later turn into vid clips
  let slideAnim = useRef();
  useEffect(() => {
    gsap.set(slideAnim.current, {
      visibility: "hidden",
    });
    gsap.fromTo(
      slideAnim.current,
      15,
      { x: "100%" },
      {
        x: "-300%",
        ease: Power3.inOut,
        repeat: -1,
        yoyo: true,
        delay: 2,
        visibility: "visible",
        stagger: {
          each: 0.5,
        },
      }
    );
  }, []);

  return (
    <div ref={slideAnim} className="slide slide-1">
      <img src={site_img} alt="shopImage" />
      <a href={site_link} className="shop-item">
        {site_title}
      </a>
    </div>
  );
}
