// eslint-disable-next-line import/no-anonymous-default-export, no-undef
export default [
  {
    id: "shop",

    serviceLink: "https://pangisa.co.ug/shop/Home/",
    serviceTitle: "Shop an Item",
    icon: "fa-solid fa-cart-shopping",
    description:
      "Shop from over 1000 Trade Assured vendor’s now from wherever you are.",
    isHover: false,
  },
  {
    id: "rent",

    serviceLink: "https://pangisa.co.ug/Index/rentals",
    serviceTitle: "Rent an Equipment",
    icon: "fa-solid fa-truck-moving",
    description:
      "Rent or lease an item from over 1000 Trade Assured Vendor’s now from wherever you are.",
    isHover: false,
  },
  {
    id: "invest",

    serviceLink: "service_href",
    serviceTitle: "Make Investiment",
    icon: "fa-solid fa-cart-flatbed",
    description: "Lorem ipsum dolor sit amet consectetur adipisicing elit. ",
    isHover: false,
  },
  {
    id: "manage",
    serviceLink: "service_href",
    serviceTitle: "Manage Your Rentals",
    icon: "fa-solid fa-sack-dollar",
    description: "Lorem ipsum dolor sit amet consectetur adipisicing elit. ",
    isHover: false,
  },
  {
    id: "orderManagement",
    serviceLink: "service_href",
    serviceTitle: "Order management",
    icon: "fa-solid fa-sack-dollar",
    description: "Lorem ipsum dolor sit amet consectetur adipisicing elit. ",
    isHover: false,
  },
  {
    id: "taxComplianceMgnt",
    serviceLink: "service_href",
    serviceTitle: "Tax & Compliance Managememt",
    icon: "fa-solid fa-sack-dollar",
    description: "Lorem ipsum dolor sit amet consectetur adipisicing elit. ",
    isHover: false,
  },
  {
    id: "legalManagement",
    serviceLink: "service_href",
    serviceTitle: "Legal Managememt",
    icon: "fa-solid fa-sack-dollar",
    description: "Lorem ipsum dolor sit amet consectetur adipisicing elit. ",
    isHover: false,
  },
  {
    id: "tourismManagement",
    serviceLink: "service_href",
    serviceTitle: "Tourism Management",
    icon: "fa-solid fa-sack-dollar",
    description: "Lorem ipsum dolor sit amet consectetur adipisicing elit. ",
    isHover: false,
  },
  {
    id: "recordsManagement",
    serviceLink: "service_href",
    serviceTitle: "Records Management",
    icon: "fa-solid fa-sack-dollar",
    description: "Lorem ipsum dolor sit amet consectetur adipisicing elit. ",
    isHover: false,
  },
  {
    id: "securityManagement",
    serviceLink: "service_href",
    serviceTitle: "Security Management",
    icon: "fa-solid fa-sack-dollar",
    description: "Lorem ipsum dolor sit amet consectetur adipisicing elit. ",
    isHover: false,
  },
  {
    id: "adsAndMediaManagement",
    serviceLink: "service_href",
    serviceTitle: "Advertising & Media Management",
    icon: "fa-solid fa-sack-dollar",
    description: "Lorem ipsum dolor sit amet consectetur adipisicing elit. ",
    isHover: false,
  },
  {
    id: "realEstateManagement",
    serviceLink: "service_href",
    serviceTitle: "Real Estate Management",
    icon: "fa-solid fa-sack-dollar",
    description: "Lorem ipsum dolor sit amet consectetur adipisicing elit. ",
    isHover: false,
  },
  {
    id: "transportManagement",
    serviceLink: "service_href",
    serviceTitle: "Transport Management",
    icon: "fa-solid fa-sack-dollar",
    description: "Lorem ipsum dolor sit amet consectetur adipisicing elit. ",
    isHover: false,
  },
  {
    id: "procurementAndFinance",
    serviceLink: "service_href",
    serviceTitle: "Procurement & Finance Management",
    icon: "fa-solid fa-sack-dollar",
    description: "Lorem ipsum dolor sit amet consectetur adipisicing elit. ",
    isHover: false,
  },
  {
    id: "HRManagement",
    serviceLink: "service_href",
    serviceTitle: "HR Management",
    icon: "fa-solid fa-sack-dollar",
    description: "Lorem ipsum dolor sit amet consectetur adipisicing elit. ",
    isHover: false,
  },
  {
    id: "licienceManagement",
    serviceLink: "service_href",
    serviceTitle: "Licience Management",
    icon: "fa-solid fa-sack-dollar",
    description: "Lorem ipsum dolor sit amet consectetur adipisicing elit. ",
    isHover: false,
  },
  {
    id: "globalTrade",
    serviceLink: "service_href",
    serviceTitle: "Global Trade & Pandemics Management",
    icon: "fa-solid fa-sack-dollar",
    description: "Lorem ipsum dolor sit amet consectetur adipisicing elit. ",
    isHover: false,
  },
  {
    id: "stockExchange",
    serviceLink: "service_href",
    serviceTitle: "Stock Exchange Management",
    icon: "fa-solid fa-sack-dollar",
    description: "Lorem ipsum dolor sit amet consectetur adipisicing elit. ",
    isHover: false,
  },
];
