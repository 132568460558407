import React, { useState, useEffect, useRef } from "react";
import { gsap } from "gsap";

const AboutPangisa = (props) => {
  //setting refs
  const vidRefs = useRef(null);
  const textLeft1 = useRef(null);
  const textLeft2 = useRef(null);
  const textLeft3 = useRef(null);
  const textLeft4 = useRef(null);

  //show about pangisa

  //animate up about pangisa section
  useEffect(() => {
    const tlAbout = gsap.timeline({
      defaults: { duration: 0.75, ease: "power3.out" },
    });
    if (props.hideAbout) {
      tlAbout.to(".fifth-page", {
        y: "0%",
        opacity: 1,
      });

      tlAbout.from(vidRefs.current, { y: "-50%", opacity: 0 }, "<");
      tlAbout.from(textLeft1.current, { x: "-10%", opacity: 0 });
      tlAbout.from(textLeft2.current, { x: "10%", opacity: 0 });
      // animating in how pangisa works
      tlAbout.fromTo(
        ".cta-1",
        { x: "100%", opacity: 0.5 },
        { x: 0, opacity: 1 },
        "<"
      );
      tlAbout.fromTo(
        ".cta-3",
        { x: "-100%", opacity: 0.5 },
        { x: 0, opacity: 1 },
        "<"
      );
      tlAbout.fromTo(
        ".cta-2",
        { y: "-100%", opacity: 0.5 },
        { y: 0, opacity: 1 },
        "<"
      );
      tlAbout.from(textLeft3.current, { x: "-10%", opacity: 0 });
      tlAbout.from(textLeft4.current, { x: "10%", opacity: 0 });
    }
  }, [props.hideAbout]);

  //animate out about pangisa section

  function handleHideAboutPangisa() {
    props.hideAbout();
  }
  //show contact form
  function handleShowContactForm() {
    props.showContactForm((prev) => !prev);
  }
  return (
    <div className="fifth-page">
      <div className="about-pangisa_title">
        <div className="cta-text">
          <h6 className="title">
            <span className="cta-1">HOW</span>
          </h6>
          <h6 className="title">
            <span className="cta-2">PANGISA</span>
          </h6>
          <h6 className="title">
            <span className="cta-3">WORKS</span>
          </h6>
        </div>

        <i onClick={handleHideAboutPangisa} className="fa-solid fa-xmark"></i>
      </div>
      <div className="phone-video">
        <video
          ref={vidRefs}
          src="../images/iphone-camera-video.mp4"
          autoPlay
          muted
          loop
        ></video>
      </div>
      <div className="product-info-container">
        <div className="left-info">
          <h3 ref={textLeft1}>
            Scaling from an online <br />
            <span> fully secured trade assured E – Commerce</span>
            <br />
            Rental & leasing E-shop platform...
          </h3>
          <h3 ref={textLeft3}>
            <span>Shop from a multitude of vendors </span>
            <br />
            from the education, health, agriculture, insurance, microfinance...
          </h3>
        </div>
        <div className="right-info">
          <h3 ref={textLeft2}>
            <span>Get support with custom made in-built</span>
            <br />
            ERP solution for Events, Order, tax compliance management...
          </h3>
          <h3 ref={textLeft4}>
            <span>Look no further than Pangisa</span>. Connect to us now to
            enjoy endless digital experiences and benefits
            <button
              onClick={handleShowContactForm}
              className="about-page-contact_us"
            >
              <span>Reach Us Now</span>
              <svg
                width="40"
                height="24"
                xmlns="http://www.w3.org/2000/svg"
                fill-rule="evenodd"
                clip-rule="evenodd"
              >
                <path d="M21.883 12l-7.527 6.235.644.765 9-7.521-9-7.479-.645.764 7.529 6.236h-21.884v1h21.883z" />
              </svg>
            </button>
          </h3>
        </div>
      </div>
    </div>
  );
};

export default AboutPangisa;
