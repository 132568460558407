import React, { useEffect } from "react";
import "../styles/content.css";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

const Content = () => {
  return (
    <section id="benefits" className="benefits">
      <video
        className="smoke-video"
        src="./images/smoke-video.mp4"
        autoPlay
        loop
        muted
      ></video>
      <header className="benefits-head">
        <div className="benefits-wrapper">
          <div className="benefit-line-left"></div>
          <h2>Experience value with Pangisa </h2>

          <div className="benefit-line-right"></div>
        </div>
      </header>
      <div className="cards">
        <div className="card shop-card">
          <div className="card-icon">
            <i className="fa-solid fa-cart-shopping"></i>
          </div>
          <h4>Shop from anywhere at any time</h4>
          <p>
            Enjoy the convenience of accessing multiple trade assured vendors
            and e-shops from anywhere at any time even from the comfort of your
            home, workplace, meeting.
          </p>
        </div>
        <div className="card rent-card">
          <div className="card-icon">
            <i className="fa-solid fa-cash-register"></i>
          </div>
          <h4>Rent or lease an item from anywhere</h4>
          <p>
            Enjoy the convenience of accessing multiple trade assured vendors
            renting or leasing items from anywhere at any time even from the
            comfort of your home, workplace, meeting.
          </p>
        </div>
        <div className="card">
          <div className="card-icon">
            <i class="fa-solid fa-bars-progress"></i>
          </div>
          <h4>Manage your business digitally </h4>
          <p>
            Manage your business digitally using a multitude of information
            management solutions inbuilt within the Pangisa one stop solution
            and experience maximum value, revenue growth, client service
            delivery, improvement of quality of processes while allowing you to
            focus on your core business.
          </p>
        </div>
        <div className="card">
          <div className="card-icon">
            <i className="fa-solid fa-truck-moving"></i>
          </div>
          <h4>Delivery at your Door step</h4>
          <p>
            Save time and transport previously spent in travelling long
            distances long for providers, shops. Shop or rent an item now
            through Pangisa and enjoy delivery of items to your location at the
            lowest costs possible.
          </p>
        </div>
      </div>
    </section>
  );
};

export default Content;
