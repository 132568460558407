import React, { useState, useEffect, useRef } from "react";
import termsAndConditions from "./termsAndConditionData.js";
import { gsap } from "gsap";

const TermsAndConditions = (props) => {
  //terms and conditions

  const termsInfo = termsAndConditions.map((prevTerms, i) => {
    return (
      <div className="terms-conditions-container" key={i}>
        <h3 className="terms-condition-title">{prevTerms.title}</h3>
        <p className="terms-condition-description">{prevTerms.description}</p>
      </div>
    );
  });

  //animate how_workds section
  const termsConditionsImgRef = useRef(null);
  useEffect(() => {
    const tlBorder = gsap.timeline({
      defaults: { duration: 1, ease: "power3.out" },
    });
    if (props.isShown) {
      tlBorder.from(termsConditionsImgRef.current, { y: "-100%", opacity: 0 });
    }
  }, [props.isShown]);

  return (
    <>
      <div className="left-content">
        <video
          ref={termsConditionsImgRef}
          src="../images/rolling.mp4"
          autoPlay
          muted
          loop
        ></video>
      </div>
      <div className="right-content">{termsInfo}</div>
    </>
  );
};
export default TermsAndConditions;
