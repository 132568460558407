// eslint-disable-next-line import/no-anonymous-default-export, no-undef
export const vendor = [
  "Peruse through the Pangisa terms and conditions to familiarize yourself withthem",
  "Create an account with us as a vendor and select the catogory to which you would like to list your item(s)",
  "Include a brief description of your item(s) and attached necessary photos for verification purposes.",
  "Make sure to specify availability of your items as well.",
  "Price your item with a daily, weekly, monthly or annual rate to enable us provide various options for our clients",
  "Sit back, relax and wait for your item(s) to be pangisa'd!",
];

// Vendor data
export const client = [
  "Create an account with us to be able to access the unnlimited items uploadedon our platform each second!",
  "Peruse through our platform to view the varying items listed",
  "See something you like? Why not place an order?",
  "Make sure to specify where the pickup or delivery location.",
  "Place your order.",
  "Your shall receive a text message and an email with a confirmation from our end.",
  " Enjoy your order!",
];
