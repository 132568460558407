// eslint-disable-next-line import/no-anonymous-default-export, no-undef
export default [
  {
    question: "How does Pangisa work? ",
    answer:
      "Kindly go to the upper right corner of your screen and click on register. Choose between a client account and a business account. The business account is for a vendor intending to offer products for hire while the client account is a user/customer account. Fill in all the necessary details and a confirmation will be sent to your email to activate your account.",
  },
  {
    question: "Who is eligible to list on pangisa?",
    answer:
      "Kindly go to the upper right corner of your screen and click on register. Choose between a client account and a business account. The business account is for a vendor intending to offer products for hire while the client account is a user/customer account. Fill in all the necessary details and a confirmation will be sent to your email to activate your account.",
  },
  {
    question: "I have forgotten my password, how can I reset? ",
    answer:
      "Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon tempor, sunt You can click login at the upper right corner of your pangisa screen, fill in your email and click on forgot password. A password reset link will be shared to your email. Follow the instructions and you shall reset your password.",
  },
  {
    question: "How do I place an order? ",
    answer:
      "After perusing and finding what you would like to hire follow the below steps. A confirmaton email and SMS will be sent to you",
  },
  {
    question: "How do I track my order?",
    answer:
      "After perusing and finding what you would like to hire follow the below steps",
  },
  {
    question: "Are the products listed on Pangisa verified? ",
    answer:
      "After perusing and finding what you would like to hire follow the below steps.Are the products listed on Pangisa verified?",
  },
  {
    question:
      "Will my account be deactivated if I don't use it for a long time? ",
    answer:
      "After perusing and finding what you would like to hire follow the below steps. No. We do not deactivate your account after long periods of dormancy. We actually",
  },
];
