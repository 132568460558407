import React from "react";

const NavLinksMobile = (props) => {
  return (
    <div className="nav-links-mobile">
      <ul
        ref={props.navLinks}
        onMouseEnter={props.handleMouseEnter}
        onMouseOut={props.handleMouseLeave}
      >
        <li className="nav-item">
          <a href="#home">Home</a>
        </li>
        <li className="nav-item">
          <a href="#services">Services</a>
        </li>
        <li className="nav-item">
          <a href="#benefits">Benefits</a>
        </li>
        <li className="nav-item">
          <a href="#trade-assured">Trade Assured</a>
        </li>
      </ul>
      <div
        ref={props.cart}
        onMouseEnter={props.handleMouseEnterCartSec}
        onMouseLeave={props.handleMouseLeaveCartSec}
        className="cart-container"
      >
        <div ref={props.headerBtns}>
          <button className="cart-btn" onClick={props.handleCart}>
            <i className="fa-solid fa-cart-shopping"></i>
          </button>
          <button className="help-btn" onClick={props.handleHelp}>
            <i className="fa-solid fa-circle-info"></i>
          </button>
        </div>
      </div>
    </div>
  );
};

export default NavLinksMobile;
