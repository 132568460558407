import React from "react";
import "../styles/cart.css";

const Cart = (props) => {
  return (
    <>
      <div ref={props.cartInfoAnim} className="cart-info-container">
        <div className="cancel-cart">
          <h2 className="text-logo">Pangisa</h2>
          <i onClick={props.handleCancelCart} className="fa-solid fa-xmark"></i>
        </div>
        <div className="visited-services_container">
          <h3 className="visited-header">What You Visited</h3>
          <div className="visited-list">
            <ul>
              <li>Shopping site</li>
              <li>Rent site</li>
            </ul>
          </div>
        </div>
      </div>
      <div ref={props.cartInfoRevealAnim} className="cart-info-reveal"></div>
    </>
  );
};

export default Cart;
